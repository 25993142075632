//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "xModal",
  props: {
    //模态窗的唯一标识（不能与其他模态框重复）
    name: {
      type: String,
      default: "",
    },

    //模态窗的标题
    title: {
      type: String,
      default: "",
    },

    //当存在标题时可显示右上角是否存在关闭按钮
    showCrossBtn: {
      type: Boolean,
      default: false,
    },

    //确认按钮的内容（为空则没有按钮）
    confirmBtnText: {
      type: String,
      default: "",
    },

    //取消按钮的内容（为空则没有按钮）
    cancelBtnText: {
      type: String,
      default: "",
    },

    //确认按钮图标
    confirmBtnIcon: {
      type: String,
      default: "",
    },

    //取消按钮图标
    cancelBtnIcon: {
      type: String,
      default: "",
    },

    //宽度
    width: {
      type: String | Number,
      default: "600",
    },

    //高度
    height: {
      type: String | Number,
      default: "auto",
    },

    //最小宽度
    minWidth: {
      type: Number,
      default: 0,
    },

    //最小高度
    minHeight: {
      type: Number,
      default: 0,
    },

    //最大宽度
    maxWidth: {
      type: Number,
      default: 99999999,
    },

    //最大高度
    maxHeight: {
      type: Number,
      default: 99999999,
    },

    //模态框X轴（0-1.0）
    pivotX: {
      type: Number,
      default: 0.5,
    },

    //模态框Y轴（0-1.0）
    pivotY: {
      type: Number,
      default: 0.5,
    },

    //自定义样式
    classes: {
      type: String | Array,
      default: "v--modal",
    },

    //打开模态窗的延迟，单位：秒
    delay: {
      type: Number,
      default: 0,
    },

    //是否自适应宽度
    adaptive: {
      type: Boolean,
      default: true,
    },

    //是否可以拉伸大小
    resizable: {
      type: Boolean,
      default: false,
    },

    //是否可以自由拖拽
    draggable: {
      type: Boolean,
      default: false,
    },

    //如果height属性为auto，并且模式高度超过窗口高度，则可以滚动
    scrollable: {
      type: Boolean,
      default: function () {
        return this.height == "auto";
      },
    },

    //点击遮罩层是否关闭弹窗
    closeOnDimmer: {
      type: Boolean,
      default: true,
    },
    //点击确认按钮是否能关闭模态框，默认点击不会关闭
    isConfirmClose: {
      type: Boolean,
      default: false,
    },

    //点击取消按钮是否能关闭模态框，默认点击就关闭
    isCancelClose: {
      type: Boolean,
      default: true,
    },

    //是否固定头尾，仅让中间区域滚动
    isFixed: {
      type: Boolean,
      default: false,
    },

    //打开模态框后需要改变的路由链接，默认为空，不改变
    //路径开头一定有斜杠才会从根域名替换地址 如果url="/aaa/bbb"，原路由为"http://www.a.cn/ccc"，会变为"http://www.a.cn/aaa/bbb"
    //不带斜杠就会变成在地址后面追加参数 如果url="aaa/bbb"，原路由为"http://www.a.cn/ccc"，会变为"http://www.a.cn/ccc/aaa/bbb"
    url: {
      type: String,
      default: "",
    },

    //路由来源，表示模态框关闭后要返回的路由地址
    //这个路径开头一定要带斜杠，如"/report"
    referer: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isFirstOpen: true,
      loadCancel: false,
      loadConfirm: false,
      issafariBrowser: false,
    };
  },
  computed: {
    classess() {
      let res = this.classes;
      if (typeof this.classes == "string") {
        res = [this.classes];
      }

      if (this.classes.indexOf("v--modal") < 0) {
        res.unshift("v--modal");
      }

      if (this.isFixed) {
        res.push("isFix");
      }
      return res;
    },
  },
  watch: {
    url(val) {
      if (this.url && !this.isFirstOpen) {
        history.pushState(null, "", val);
      }
    },
  },
  mounted() {
    this.$emit("loadSuccess");
    //判断移动端safari
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.issafariBrowser =
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent);
    } else {
      this.issafariBrowser = false;
    }
    // console.log(this.issafariBrowser)
  },
  methods: {
    //点击确认
    onConfirm() {
      if (this.loadConfirm) return;
      if (this.isConfirmClose) {
        this.$modal.hide(this.name);
      }
      this.$emit("onConfirm");
    },

    //点击取消
    onCancel() {
      if (this.loadCancel) return;
      if (this.isCancelClose) {
        this.$modal.hide(this.name);
      }
      this.$emit("onCancel");
    },

    //打开前
    beforeOpen(e) {
      this.$emit("beforeOpen", e);
    },
    //关闭前
    beforeClose(e) {
      this.$emit("beforeClose", e);
    },

    //打开后
    opened(e) {
      if (process.env.NODE_ENV === "prodution") {
        const body = document.getElementById("app");
        if (body.append) {
          body.append(this.$refs.location.$el);
        } else {
          body.appendChild(this.$refs.location.$el);
        }
      }
      if (this.url) {
        history.pushState(null, "", this.url);
        this.isFirstOpen = false;
      }
      this.$emit("opened", e);
    },

    //关闭后
    closed(e) {
      if (this.referer) {
        history.pushState(null, "", this.referer);
      }
      this.$emit("closed", e);
    },

    /**
     * 切换提交按钮的加载状态
     * @param {Boolean} bool true变为加载，false恢复原样
     */
    loadBtn(bool) {
      if (this.confirmBtnText) {
        this.loadConfirm = bool;
      } else if (this.cancelBtnText) {
        this.loadCancel = bool;
      }
    },
  },
};
